import Image from "next/image";
import { useRouter } from "next/router";
import globalIcon from "../../assets/images/icons/global-icon.png";

const LanguageSwitcher = () => {
  const router = useRouter();
  const currentLocale = router.locale || router.defaultLocale;
  const availableLocales = [
    { locale: "en", label: "English" },
    { locale: "fr", label: "Français" },
  ];

  return (
    <div className="languageSwitcher">
        
        <Image src={globalIcon} />

        <select
            value={currentLocale}
            onChange={(e) => {
            router.push(router.asPath, router.asPath, { locale: e.target.value });
            }}
            className="select"
        >
            {availableLocales.map((lang) => (
            <option key={lang.locale} value={lang.locale}>
                {lang.label}
            </option>
            ))}
        </select>
    </div>
  );
};
export default LanguageSwitcher;
