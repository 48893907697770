// components/common/Form.jsx
import React, { useEffect, useState } from "react";
import eyeIcon from "../assets/images/icons/Open Eyes.svg";
import eyeSlashIcon from "../assets/images/icons/ShowEye.png";
import Image from "next/image";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";

const Form = ({
  fields,
  onSubmit,
  validationSchema,
  btnText,
  Description,
  ActionText,
  href,
  textarea,
  buttonAlignment = "center",
  buttonWidth = "100%", 
  forgotPasswordtext,
  forgotPasswordhref,

}) => {
 
  const getInitialValues = React.useCallback(() => {
    return fields.reduce((acc, field) => {
      acc[field.name] = field.defaultValue || "";
      return acc;
    }, {});
  }, [fields]);

  const [values, setValues] = useState(getInitialValues());
  const [passwordVisibility, setPasswordVisibility] = useState({});
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  useEffect(() => {
    fetch("https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code")
      .then((response) => response.json())
      .then((data) => {
        // Filtrer les pays pour exclure Israël
        const filteredCountries = data.countries.filter(country => country.value !== "IL");
        setCountries(filteredCountries);
  
        // Trouver le pays par défaut (Tunisia)
        const defaultCountry = filteredCountries.find(country => country.value === "TN");
        if (defaultCountry) {
          setValues(prev => ({
            ...prev,
            country: defaultCountry.value,
          }));
          setSelectedCountry(defaultCountry);
        }
      });
  }, []);
  
  
  
  
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  useEffect(() => {
    const initialValues = getInitialValues();

    setValues(initialValues);
  }, [getInitialValues]);

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    setValues({
      ...values,
      [name]: file,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    if (Object.keys(validationErrors).length === 0) {
      onSubmit(values);
    } else {
      setErrors(validationErrors);
    }
  };

  const validate = (values) => {
    const validationErrors = {};
    const numberPattern = /\d/;
    const phoneNumberPattern = /^\+\d{1,4}\s?\d{8,12}$/;
    const strongPasswordPattern =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    for (const key in validationSchema) {
      const value = values[key];
      const schema = validationSchema[key];

      if (schema.required && !value) {
        validationErrors[key] = `* ${key} is required`;
      }
      if (schema.maxLength && value.length > schema.maxLength) {
        validationErrors[
          key
        ] = `${key} should not exceed ${schema.maxLength} characters`;
      }
      if (schema.noNumbers && numberPattern.test(value)) {
        validationErrors[key] = `${key} should not contain numbers`;
      }
      if (schema.phoneNumber && !phoneNumberPattern.test(value)) {
        validationErrors[
          key
        ] = `Phone number should be in the format +216 22222222`;
      }
      if (schema.strongPassword) {
        if (!strongPasswordPattern.test(value)) {
          validationErrors[
            key
          ] = `${key} should contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character`;
        }
      }
    }
    return validationErrors;
  };

  const handlePasswordToggle = (name) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  const renderField = (field) => {
    const inputClassName = field.className || "form-input";

    switch (field.type) {
      case "text":
      case "email":
      case "number":
      case "date":
        return (
          <input
            type={field.type}
            name={field.name}
            id={field.name}
            value={values[field.name]}
            onChange={handleChange}
            placeholder={field.placeholder || ""}
            className={
              field.type === "date" ? "form-date-input" : inputClassName
            }
          />
        );
      case "password":
        return (
          <div className="password-container">
            <input
              type={passwordVisibility[field.name] ? "text" : "password"}
              name={field.name}
              id={field.name}
              value={values[field.name]}
              onChange={handleChange}
              placeholder={field.placeholder || ""}
              className={inputClassName}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => handlePasswordToggle(field.name)}
            >
              <Image
                src={passwordVisibility[field.name] ? eyeIcon : eyeSlashIcon}
                alt="password-icon"
                className="password-icon"
                priority={true}
              />
            </button>
          </div>
        );
      case "textarea":
        return (
          <textarea
            name={field.name}
            id={field.name}
            value={values[field.name]}
            onChange={handleChange}
            className="form-input"
            placeholder={field.placeholder || ""}
          />
        );
      case "select":
        return (
          <select
            name={field.name}
            id={field.id}
            value={values[field.name]}
            onChange={handleChange}
            className="form-input"
          >
            {field.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
        case "country":
          return (
            <Select
              options={countries}
              value={selectedCountry} 
              onChange={(selectedOption) => {
                setSelectedCountry(selectedOption);
                setValues({
                  ...values,
                  [field.name]: selectedOption?.label?.replace(/^[^\w]*\s/, ""),
                  // countryLabel: selectedOption.label, 
                });
              }}
              className=""
            />
          );
        
      case "phone":
        return (
          <PhoneInput
            international
            country={"tn"}
            excludeCountries={["il"]}
            value={values[field.name]}
            onChange={(value, country) => {
              if (country && country.dialCode) {
                const phoneNumberWithoutDialCode = value.slice(
                  country.dialCode.length
                );
                setValues({
                  ...values,
                  [field.name]: `+${country.dialCode} ${phoneNumberWithoutDialCode}`,
                  country: country.name,
                  CountryCode: country.dialCode,
                });
              } else {
                setValues({
                  ...values,
                  [field.name]: value,
                });
              }
            }}
            placeholder="+216 992345687"
            className=""
          />
        );
      case "checkbox":
        return (
          <div className="form-checkbox-container">
            <input
              type="checkbox"
              name={field.name}
              id={field.name}
              checked={values[field.name]}
              onChange={handleChange}
              className="form-checkbox1"
            />
            <label
              htmlFor={field.name}
              className={field.labelClassName || "form-checkbox-label"}
            >
              {field.label}
            </label>
          </div>
        );
      case "radio":
        return field.options.map((option) => (
          <label key={option.value} className="form-radio">
            <input
              type="radio"
              name={field.name}
              value={option.value}
              checked={values[field.name] === option.value}
              onChange={handleChange}
              className="form-radio-input"
            />
            <div style={{ paddingLeft: "1rem" }}>{option.label}</div>
          </label>
        ));
      case "file":
        return (
          <input
            type="file"
            name={field.name}
            id={field.name}
            onChange={(e) => handleFileChange(e, field.name)}
            className="form-file-input"
          />
        );
      default:
        return null;
    }
  };

  const renderFieldsByGroup = () => {
    const groupedFields = fields.reduce((acc, field) => {
      acc[field.group] = acc[field.group] || [];
      acc[field.group].push(field);
      return acc;
    }, {});

    return Object.entries(groupedFields).map(([group, fieldsInGroup]) => (
      <div key={group} className="form-group-row">
        {fieldsInGroup.map((field) => (
          <div key={field.name} className="form-group">
            {field.type !== "checkbox" && (
              <label
                className={field.labelClassName || "form-label"}
                htmlFor={field.name}
              >
                {field.label}
              </label>
            )}
            {renderField(field)}
            {errors[field.name] && (
              <p className="form-error">{errors[field.name]}</p>
            )}
          </div>
        ))}
      </div>
    ));
  };

  // Determine button styles based on props
  const buttonStyles = {
    textAlign: buttonAlignment === buttonAlignment,
    flexDirection: buttonAlignment === "right" ? "" : "column",
    justifyContent:
      buttonAlignment === "right"
        ? "flex-end"
        : buttonAlignment === "left"
        ? "flex-start"
        : "center",
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="custom-form">
        {renderFieldsByGroup()}
        <div className="d-flex " style={{ ...buttonStyles }}>
          <button
            type="submit"
            className="form-submit-button"
            style={{ width: buttonWidth }}
          >
            {btnText}
          </button>
          <p
            style={{ marginTop: "1rem" }}
            className="text-gray form-description"
          >
            {" "}
            {Description}
            <a href={href} aria-label={ActionText || 'Action link'}>
            {ActionText && <span className="text-primary"> &nbsp; {ActionText} </span>}
            </a>
          </p>
            <a href={forgotPasswordhref || "#"}   aria-label={forgotPasswordtext || 'Forgot password link'} className="forgot-password-text">
            {forgotPasswordtext && <span> {forgotPasswordtext} </span>}
            </a>
        </div>
      </form>
    </div>
  );
};

export default Form;
  